import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/app/Hero"
import HeaderButton from "~sections/app/Header"
import ServicesSection from "~sections/app/Services"
import AboutSection from "~sections/app/About"
import ContentSectionOne from "~sections/app/ContentOne"
import PricingSection from "~sections/app/Pricing"
import TestimonialSection from "~sections/app/Testimonial"
import PromoSection from "~sections/app/Promo"
import CtaSection from "~sections/app/Cta"
import FooterFour from "~sections/Blog/Footer/FooterSection"
import { StaticImage as Img } from 'gatsby-plugin-image'


const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock : <HeaderButton className="d-none d-sm-flex" children="SignFree SEO Analysis"/>,
}

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
      <div style={{ padding: "2rem" }}>
        <h1 style={{ marginTop: "8rem", marginBottom: "2rem" }}>Changes to Luna Journal's Data Management</h1>

        <Row>
        <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <p>
            Luna Journal, the popular pet health management app, is changing its data storage practices to prioritize the security and privacy of pet health information. Effective 6/1/2024, Luna Journal will transition to storing all user data directly on the device, eliminating cloud-based data storage.
          </p>

          <p>
          This important update comes in response to a growing trend of state legislation that treats pet health data with the same level of protection as human health data. These regulations require companies to implement robust data security measures and offer users greater control over how their pet's information is collected, used, and stored.
          </p>

          <p>
          "As a solo developer dedicated to Luna Journal, I'm passionate about empowering pet owners with tools to manage their companions' health," says Brad Cypert, creator of Luna Journal. "However, the increasing complexity of data security regulations makes it challenging for a small operation like mine to offer the level of compliance required for cloud-based storage of pet health data."
          </p>

          <p>
          This update will remove the app's data collaboration functionalities that were previously a selling point. However, Luna Journal remains committed to providing pet owners with a user-friendly platform to track vital signs, medications, vet visits, and other critical health information.
          </p>

          <p>
          "I understand that this change may be inconvenient for some users. I am committed to exploring alternative solutions, such as secure local backups and data export functionalities, in future updates.", added Brad.
          </p>

          <p>
          The data migration changes will come in two waves, and the silver lining is that these changes will also simplify parts of the app. The first update, currently in testing and planning to release soon, will migrate your data for you. This update will also remove households from the app.
          </p>
          <p>
          The second phase of this transition will ultimately remove the need to sign in, as data will be kept on your device. This phase will happen after the 6/1/2024 migration deadline. The goal with these updates are to comply with new legislation but to also continue to focus on what makes Luna Journal great for it's users.
          </p>
        </Col>
        </Row>
        
      </div>
      <FooterFour />
    </PageWrapper>
  )
}
